/* eslint max-lines: ["error", 270] */
import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { DateTime } from 'luxon';
import { useState } from 'react';
import useMarkCleaningsAsPaid from '../hooks/markCleaningsAsPaid';
import { cleaningListQuery } from '../queries/cleaningQueries';
import CleaningForm from './CleaningForm';

const moneyFormatter = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    maximumFractionDigits: 0,
});

const Cleaning = () => {
    const [showCleaningForm, setShowCleaningForm] = useState(false);
    const {
        data: { cleaningList = [] } = {
            cleaningList: [],
        },
        refetch,
    } = useQuery<{
        cleaningList: {
            id: number;
            time: string;
            note: string;
            value: number;
            paid: boolean;
        }[];
    }>(cleaningListQuery);

    const markCleaningsAsPaid = useMarkCleaningsAsPaid();

    const suggestedTime = `${DateTime.local().toSQLDate()} 12:00`;

    const [selectedItems, setSelectedItems] = useState<number[]>([]);

    const selectedCleanings = cleaningList.filter(({ id }) =>
        selectedItems.includes(id)
    );

    const selectedTotalValue = selectedCleanings.reduce(
        (a, b) => a + b.value,
        0
    );

    return (
        <>
            <TableContainer component={Paper}>
                <Table padding="none" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Time</TableCell>
                            <TableCell align="right">Note</TableCell>
                            <TableCell align="right">Value</TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cleaningList.map(row => (
                            <TableRow
                                key={row.id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.time}
                                </TableCell>
                                <TableCell align="right">{row.note}</TableCell>
                                <TableCell align="right">
                                    {moneyFormatter.format(row.value)}
                                </TableCell>
                                <TableCell align="right">
                                    <Checkbox
                                        checked={selectedItems.includes(row.id)}
                                        onClick={() => {
                                            if (
                                                selectedItems.includes(row.id)
                                            ) {
                                                setSelectedItems(
                                                    selectedItems.filter(
                                                        item => item !== row.id
                                                    )
                                                );
                                            } else {
                                                setSelectedItems([
                                                    ...selectedItems,
                                                    row.id,
                                                ]);
                                            }
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Total</TableCell>
                            <TableCell align="right">
                                {moneyFormatter.format(
                                    cleaningList.reduce(
                                        (a, { value }) => a + value,
                                        0
                                    )
                                )}
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="right">Selected</TableCell>
                            <TableCell align="right">
                                {moneyFormatter.format(selectedTotalValue)}
                            </TableCell>
                            <TableCell align="right">
                                {selectedTotalValue > 0 && (
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            markCleaningsAsPaid(selectedItems)
                                                .then(() => {
                                                    refetch();
                                                    setSelectedItems([]);
                                                })
                                                .catch(error => {
                                                    throw error;
                                                });
                                        }}
                                    >
                                        Pay selected
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                onClick={() => {
                    setShowCleaningForm(true);
                }}
            >
                Add
            </Button>
            {showCleaningForm && (
                <CleaningForm
                    suggestedTime={suggestedTime}
                    onClose={() => {
                        setShowCleaningForm(false);
                    }}
                />
            )}
        </>
    );
};

export default Cleaning;
