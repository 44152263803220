/* eslint @typescript-eslint/ban-types: 0 */
import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import { MakeGenerics, useNavigate, useSearch } from '@tanstack/react-location';
import AbstractButton from '../../base-ui/components/button/AbstractButton';
import {
    messageTemplatesQuery,
    MessageTemplatesQueryResult,
} from '../../booking/queries/messageTemplate';
import ErrorBox from '../../core/components/ErrorBox';
import useApiKeys from '../../core/hooks/useApiKeys';
import useUpdateApiKeyByName from '../../core/hooks/useUpdateApiKeyByName';
import assert from '../../core/util/assert';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import extractCookieFromCurl from '../domain/extractCookieFromCurl';
import ApiKeyEdit from './ApiKeyEdit';
import InlineKeyUpdateButton from './InlineKeyUpdateButton';
import MessageTemplateView from './MessageTemplateView';

type LocationGenerics = MakeGenerics<{
    Search: {
        name?: string;
    };
}>;

const SettingsPage = () => {
    const { name: currentName } = useSearch<LocationGenerics>();
    const navigate = useNavigate();
    const updateApiKeyByName = useUpdateApiKeyByName();

    const apiKeys = useApiKeys({
        params: [],
    });

    const { loading, error, data } = useQuery<MessageTemplatesQueryResult>(
        messageTemplatesQuery
    );

    if (apiKeys.error) {
        return <ErrorBox error={apiKeys.error} />;
    }

    if (apiKeys.loading) {
        return <SpinnerIcon />;
    }

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error :(</p>;
    assert(data);
    const { messageTemplates } = data;

    return (
        <Box display="flex" flexDirection="row" gap={4}>
            <Box>
                <InlineKeyUpdateButton
                    onSend={content => {
                        const cookie = extractCookieFromCurl(content);
                        const record = apiKeys.data.find(
                            item => item.name === 'airbnb'
                        );

                        if (record) {
                            const value = {
                                ...JSON.parse(record.value),
                                cookie,
                            };

                            updateApiKeyByName('airbnb', {
                                value: JSON.stringify(value),
                            }).catch(updateError => {
                                throw updateError;
                            });
                        }
                    }}
                >
                    Update AirBnB key
                </InlineKeyUpdateButton>
            </Box>
            <Box>
                <strong>Api keys</strong>
                {apiKeys.data.map(apiKey => (
                    <ApiKeyEdit apiKey={apiKey} key={apiKey.id} />
                ))}
            </Box>
            <Box>
                {currentName && <MessageTemplateView name={currentName} />}
                <table>
                    <tbody>
                        {messageTemplates.map(({ name }) => (
                            <tr key={name}>
                                <td>
                                    <AbstractButton
                                        onPress={() => {
                                            navigate({
                                                to: '/templates',
                                                search: { name: name },
                                            });
                                        }}
                                    >
                                        {name}
                                    </AbstractButton>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </Box>
    );
};

export default SettingsPage;
