import { gql, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useEffect } from 'react';
import { findCountryByPhoneNumbers } from '../../core/domain/countryPrefixes';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import useUpdateBookingField from '../hooks/useUpdateBookingField';
import './BookingMessageGenerator.scss';

const CountryForm = ({
    bookingId,
    value,
    contactPhone,
}: {
    contactPhone: string;
    bookingId: number;
    value: string;
}) => {
    const {
        data: { countries, booking } = { countries: [] },
        loading: dataLoading,
    } = useQuery<{
        countries: { code: string; name: string }[];
        booking: {
            id: string;
            countryCode: string;
        };
    }>(
        gql`
            query countries($id: Int!) {
                booking(id: $id) {
                    id
                    countryCode
                }
                countries {
                    code
                    name
                }
            }
        `,
        {
            variables: {
                id: bookingId,
            },
        }
    );

    const { currentValue, setValue, update } = useUpdateBookingField({
        id: bookingId,
        field: 'countryCode',
        initialValue: value,
        defaultValue: '',
    });

    useEffect(() => {
        if (booking?.countryCode) {
            setValue(booking?.countryCode);
        } else if (countries.length) {
            const countryName = findCountryByPhoneNumbers(contactPhone);
            const country = countries.find(item => item.name === countryName);
            if (country) {
                setValue(country.code);
            }
        }
    }, [countries, booking?.countryCode]);

    if (dataLoading) {
        return <SpinnerIcon />;
    }

    const sortedCountries = countries
        .slice()
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Box>
            <FormControl>
                <InputLabel>Country</InputLabel>
                <Select
                    label="Country"
                    value={currentValue}
                    onChange={event => {
                        setValue(event.target.value);
                    }}
                >
                    <MenuItem value="">Please select</MenuItem>
                    {sortedCountries.map(({ code, name }) => (
                        <MenuItem key={code} value={code}>
                            {name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {(value || '') !== currentValue && (
                <>
                    {' '}
                    <Button onClick={update}>Save</Button>
                </>
            )}
        </Box>
    );
};

export default CountryForm;
