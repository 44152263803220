import {
    createHashHistory,
    Outlet,
    ReactLocation,
    Router,
} from '@tanstack/react-location';
import Balance from '../../balance/components/Balance';
import BankingPage from '../../banking/components/BankingPage';
import BookingView from '../../booking/components/BookingView';
import Cleaning from '../../cleaning/component/Cleaning';
import Home from '../../home/components/Home';
import SettingsPage from '../../settings/components/SettingsPage';
import TaxBreakdown from '../../taxes/components/TaxBreakdown';
import TouristTaxBreakdown from '../../taxes/components/TouristTaxBreakdown';
import InvoiceList from './InvoiceList';
import Stats from './Stats';
import TokenForm from './TokenForm';
import Layout from './common/layout/Layout';

const history = createHashHistory();

const location = new ReactLocation({ history });

const App = () => (
    <Router
        routes={[
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/booking/:id',
                element: <BookingView />,
            },
            {
                path: '/cleaning',
                element: <Cleaning />,
            },
            {
                path: '/banking',
                element: <BankingPage />,
            },
            {
                path: '/invoices',
                element: <InvoiceList />,
            },
            {
                path: '/balance',
                element: <Balance />,
            },
            {
                path: '/taxes',
                element: <TaxBreakdown />,
            },
            {
                path: '/tourist-tax',
                element: <TouristTaxBreakdown />,
            },
            {
                path: '/stats',
                element: <Stats />,
            },
            {
                path: '/settings',
                element: <SettingsPage />,
            },
        ]}
        location={location}
    >
        <Layout>
            {!localStorage.getItem('token') && <TokenForm />}
            <Outlet />
        </Layout>
    </Router>
);

export default App;
