import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { DateTime } from 'luxon';
import { Fragment } from 'react';
import CleaningCard from '../../cleaning/component/CleaningCard';
import ErrorBox from '../../core/components/ErrorBox';
import formatMoney from '../../core/formatMoney';
import assert from '../../core/util/assert';
import BookingCard from '../../main/component/BookingCard';
import {
    homeQuery,
    HomeQueryResult,
    HomeQueryResultBooking,
} from '../../main/queries';

const averageCardHeight = 270;
const cleaningCardHeight = 65;

const Home = () => {
    const { loading, error, data } = useQuery<HomeQueryResult>(homeQuery, {
        pollInterval: 3600,
    });

    if (loading) {
        return (
            <Box display="flex" flexDirection="column" gap={3}>
                <Skeleton variant="text" height={50} />
                <Skeleton variant="rectangular" height={averageCardHeight} />
                <Skeleton variant="rectangular" height={cleaningCardHeight} />
                <Skeleton variant="rectangular" height={averageCardHeight} />
                <Skeleton variant="rectangular" height={cleaningCardHeight} />
                <Skeleton variant="rectangular" height={averageCardHeight} />
                <Skeleton variant="rectangular" height={cleaningCardHeight} />
                <Skeleton variant="rectangular" height={averageCardHeight} />
                <Skeleton variant="rectangular" height={cleaningCardHeight} />
                <Skeleton variant="rectangular" height={averageCardHeight} />
                <Skeleton variant="rectangular" height={cleaningCardHeight} />
                <Skeleton variant="rectangular" height={averageCardHeight} />
                <Skeleton variant="rectangular" height={cleaningCardHeight} />
            </Box>
        );
    }

    if (error) return <ErrorBox error={error} />;

    assert(data);

    const {
        home: { bookings },
        cleaningList,
    } = data;

    const relevantBookings = bookings
        .slice()
        .filter(
            booking =>
                booking.status !== 'canceled' &&
                (!booking.detailsRecorded ||
                    !booking.hasInstructions ||
                    !booking.invoiceId ||
                    booking.status !== 'complete')
        )
        .sort((a, b) => a.checkin.localeCompare(b.checkin));

    const months = relevantBookings.reduce((a, b) => {
        const month = b.checkin.substring(0, 7);
        if (!a.includes(month)) {
            return [...a, month];
        }
        return a;
    }, [] as string[]);

    return (
        <Box>
            {months.map(month => {
                const bookingsForThisMonth = relevantBookings.filter(booking =>
                    booking.checkin.startsWith(month)
                );

                const firstBookingDateForThisMonth =
                    bookingsForThisMonth[0].checkin;

                const cleaningsForThisMonth = cleaningList
                    .filter(cleaning => cleaning.time.startsWith(month))
                    .filter(
                        cleaning =>
                            cleaning.time.substring(0, 10) >=
                                firstBookingDateForThisMonth || !cleaning.paid
                    );

                const itemsForThisMonth: (
                    | [string, 'booking', HomeQueryResultBooking]
                    | [
                          string,
                          'cleaning',
                          {
                              id: string;
                              time: string;
                              note: string;
                              value: number;
                              paid: boolean;
                          },
                      ]
                    | [string, 'cleaningPlaceholder']
                )[] = [
                    ...bookingsForThisMonth.map(
                        item =>
                            [item.checkin, 'booking', item] as [
                                string,
                                'booking',
                                typeof item,
                            ]
                    ),
                    ...cleaningsForThisMonth.map(
                        item =>
                            [item.time.substring(0, 10), 'cleaning', item] as [
                                string,
                                'cleaning',
                                typeof item,
                            ]
                    ),
                ];

                itemsForThisMonth.sort(([a], [b]) => a.localeCompare(b));

                for (let i = 0; i < itemsForThisMonth.length - 1; i++) {
                    const current = itemsForThisMonth[i];
                    const next = itemsForThisMonth[i + 1];

                    if (current[1] === 'booking' && next[1] === 'booking') {
                        itemsForThisMonth.splice(i + 1, 0, [
                            `${current[2].checkout} ${current[2].checkinTime}`,
                            'cleaningPlaceholder',
                        ]);
                    }
                }

                return (
                    <Fragment key={month}>
                        {month.endsWith('01') && (
                            <h2>{month.substring(0, 4)}</h2>
                        )}
                        <h3>
                            {DateTime.fromSQL(`${month}-01`).toFormat('LLLL')}{' '}
                            {formatMoney(
                                bookingsForThisMonth.reduce(
                                    (a, b) => a + b.revenue,
                                    0
                                ),
                                'HUF'
                            )}
                        </h3>
                        {itemsForThisMonth.map(current => {
                            if (current[1] === 'booking') {
                                return (
                                    <BookingCard
                                        booking={current[2]}
                                        key={current[2].reference}
                                    />
                                );
                            }
                            if (current[1] === 'cleaning') {
                                const [, , cleaning] = current;
                                return (
                                    <CleaningCard
                                        key={cleaning.id}
                                        cleaning={cleaning}
                                    />
                                );
                            }
                            if (current[1] === 'cleaningPlaceholder') {
                                const [dateTime] = current;
                                return (
                                    <CleaningCard
                                        key={`cleaningPlaceholder${dateTime}`}
                                        suggestedTime={dateTime}
                                    />
                                );
                            }
                            return null;
                        })}
                    </Fragment>
                );
            })}
        </Box>
    );
};

export default Home;
