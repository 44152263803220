import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import { useRef, useState, type ReactNode } from 'react';

const UpdateForm = ({ onSend }: { onSend: (content: string) => void }) => {
    const [content, setContent] = useState('');

    return (
        <Box display="flex" m={2} gap={2} alignItems="center">
            <TextField
                label="Content"
                variant="outlined"
                value={content}
                onChange={event => {
                    setContent(event.target.value);
                }}
            />
            <Button
                variant="contained"
                disabled={!content}
                onClick={() => {
                    setContent('');
                    onSend(content);
                }}
            >
                Update
            </Button>
        </Box>
    );
};

const InlineKeyUpdateButton = ({
    children,
    onSend,
}: {
    children: ReactNode;
    onSend: (content: string) => void;
}) => {
    const [open, setOpen] = useState(false);
    const ref = useRef<HTMLButtonElement | null>(null);
    return (
        <>
            <Button
                ref={ref}
                variant="contained"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                {children}
            </Button>
            <Popover
                open={open}
                anchorEl={ref.current}
                onClose={() => {
                    setOpen(false);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <UpdateForm onSend={onSend} />
            </Popover>
        </>
    );
};

export default InlineKeyUpdateButton;
