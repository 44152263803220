import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { memo } from 'react';
import useUpdateBookingField from '../hooks/useUpdateBookingField';

const BookingSelectField = memo(
    ({
        id,
        value,
        label,
        field,
        options,
    }: {
        label: string;
        field: string;
        id: number;
        value?: string;
        options: string[];
    }) => {
        const { currentValue, setValue, update } = useUpdateBookingField({
            id,
            field,
            initialValue: value,
            defaultValue: '',
        });

        return (
            <Box>
                <FormControl>
                    <InputLabel>{label}</InputLabel>
                    <Select
                        label={label}
                        value={currentValue}
                        onChange={event => {
                            setValue(event.target.value);
                        }}
                    >
                        {options.map(option => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {(value || '') !== currentValue && (
                    <>
                        {' '}
                        <Button onClick={update}>Save</Button>
                    </>
                )}
            </Box>
        );
    }
);

export default BookingSelectField;
