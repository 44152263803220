import { useApolloClient } from '@apollo/client';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Booking } from '../../domain/booking';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import useAlterBookingState from '../hooks/useAlterBookingState';

const BookingChecklistItemState = ({
    booking,
    statusField,
    label,
}: {
    label?: string;
    booking: Booking;
    statusField: keyof Booking;
}) => {
    const client = useApolloClient();
    const [alterBookingState, { loading }] = useAlterBookingState(booking.id);

    if (loading) {
        return <SpinnerIcon />;
    }

    return booking[statusField] ? (
        <FormControlLabel
            control={<Checkbox checked color="success" />}
            label={label}
        />
    ) : (
        <span
            role="button"
            tabIndex={0}
            onKeyPress={() => {
                alterBookingState({
                    variables: {
                        id: booking.id,
                        fields: { [statusField]: true },
                    },
                })
                    .then(async () => {
                        await client.reFetchObservableQueries(true);
                    })
                    .catch(error => {
                        throw error;
                    });
            }}
            onClick={() => {
                alterBookingState({
                    variables: {
                        id: booking.id,
                        fields: { [statusField]: true },
                    },
                })
                    .then(async () => {
                        await client.reFetchObservableQueries(true);
                    })
                    .catch(error => {
                        throw error;
                    });
            }}
        >
            <FormControlLabel control={<Checkbox />} label={label} />
        </span>
    );
};

export default BookingChecklistItemState;
