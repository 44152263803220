import { gql, useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import GuestDocumentView from './GuestDocumentView';
import GuestTextField from './GuestTextField';

const CountryField = ({ value }: { value: string }) => {
    const {
        loading,
        data: { countries } = {
            countries: [],
        },
    } = useQuery<{ countries: { code: string; name: string }[] }>(gql`
        {
            countries {
                code
                name
            }
        }
    `);
    if (loading) {
        return null;
    }
    return (
        <FormControl>
            <InputLabel>Country</InputLabel>
            <Select label="County" value={value}>
                <MenuItem value=""></MenuItem>
                {countries.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

const NationalityField = ({ value }: { value: string }) => {
    const {
        loading,
        data: { nationalityList } = {
            nationalityList: [],
        },
    } = useQuery<{ nationalityList: { code: string; name: string }[] }>(gql`
        {
            nationalityList {
                code
                name
            }
        }
    `);
    if (loading) {
        return null;
    }
    return (
        <FormControl>
            <InputLabel>Nationality</InputLabel>
            <Select label="Nationality" value={value}>
                <MenuItem value=""></MenuItem>
                {nationalityList.map(({ code, name }) => (
                    <MenuItem key={code} value={code}>
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

interface Guest {
    id: number;
    firstName: string;
    lastName: string;
    sex: string;
    nationality: string;
    country: string;
    birthDate: string;
    birthPlace: string;
    postcode: string;
    address: string;
    idDocumentType?: string;
    idDocumentId?: string;
    valid: boolean;
    price: number;
    touristTax: number;
    nationalityNameHU: string;
    countryNameHU: string;
}

const GuestList = ({
    guests,
    bookingId,
}: {
    guests: Guest[];
    bookingId: number;
}) => {
    const [openGuestView, setOpenGuestView] = useState<number | undefined>();
    const [removeGuest] = useMutation(
        gql`
            mutation removeGuest($guestId: Int!) {
                removeGuest(guestId: $guestId)
            }
        `,
        {
            /*
            refetchQueries: [
                {
                    query: bookingViewQuery,
                    variables: { id: bookingId },
                },
            ],
            */
        }
    );

    return (
        <Box>
            {guests.map((guest, index) => (
                <div
                    key={index}
                    style={{
                        margin: '2rem 0',
                        display: 'grid',
                        gridTemplateColumns: '50% 50%',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                        }}
                    >
                        <TextField label="First name" value={guest.firstName} />
                        <TextField label="Last name" value={guest.lastName} />
                        <TextField label="Sex" value={guest.sex} />
                        <NationalityField value={guest.nationality} />
                        <CountryField value={guest.country} />
                        <TextField label="Address" value={guest.address} />
                        <GuestTextField
                            id={guest.id}
                            label="Birth date"
                            value={guest.birthDate}
                            field="birthDate"
                        />
                        <TextField
                            label="Birth place"
                            value={guest.birthPlace}
                        />
                        <TextField label="Postcode" value={guest.postcode} />
                        <TextField
                            label="Document type"
                            value={guest.idDocumentType}
                        />
                        <TextField
                            label="Document id"
                            value={guest.idDocumentId}
                        />
                    </Box>
                    <GuestDocumentView
                        bookingId={bookingId}
                        guest={guest}
                        guestIndex={index}
                        open={openGuestView === index}
                        setOpened={() => {
                            setOpenGuestView(index);
                        }}
                        setClosed={() => {
                            setOpenGuestView(undefined);
                        }}
                        showNext={() => {
                            if (index < guests.length - 1) {
                                setOpenGuestView(index + 1);
                            } else {
                                setOpenGuestView(undefined);
                            }
                        }}
                        showPrevious={() => {
                            if (index > 0) {
                                setOpenGuestView(index - 1);
                            } else {
                                setOpenGuestView(undefined);
                            }
                        }}
                    />
                    <Box sx={{ mt: 4 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                                removeGuest({
                                    variables: {
                                        guestId: guest.id,
                                    },
                                }).catch(removeError => {
                                    throw removeError;
                                });
                            }}
                        >
                            Remove guest
                        </Button>
                    </Box>
                </div>
            ))}
        </Box>
    );
};

export default GuestList;
