const extractCookieFromCurl = (curlCommand: string) => {
    const result = /-H 'cookie: ([^\n']+)'/g.exec(curlCommand);

    if (!result) {
        return undefined;
    }

    return result[1];
};

export default extractCookieFromCurl;
