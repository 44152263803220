/* eslint @typescript-eslint/ban-types: 0, @typescript-eslint/no-explicit-any: 0, @typescript-eslint/consistent-type-definitions: 0 */
import { useMutation, useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useNavigate } from '@tanstack/react-location';
import { useCallback, useState } from 'react';
import {
    messageTemplateQuery,
    saveMessageTemplateQuery,
} from '../../booking/queries/messageTemplate';
import ErrorBox from '../../core/components/ErrorBox';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';

const MessageTemplateEditor = ({
    name,
    value: initialValue,
}: {
    name: string;
    value: string;
}) => {
    const navigate = useNavigate();

    const onFocusLost = useCallback(() => {
        navigate({ to: '/templates' });
    }, [navigate]);

    const [value, setValue] = useState(initialValue);

    const [saveMessageTemplate] = useMutation(saveMessageTemplateQuery);

    return (
        <Modal open>
            <>
                <textarea
                    value={value}
                    onChange={event => {
                        setValue(event.target.value);
                    }}
                    style={{ width: 600, height: 400 }}
                />
                <div style={{ display: 'flex', gap: '1rem' }}>
                    <Button variant="contained" onClick={onFocusLost}>
                        Close
                    </Button>
                    <Button
                        variant="contained"
                        disabled={initialValue === value}
                        onClick={() => {
                            saveMessageTemplate({
                                variables: {
                                    name,
                                    message: value,
                                },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    onFocusLost();
                                });
                        }}
                    >
                        Save
                    </Button>
                </div>
            </>
        </Modal>
    );
};

const MessageTemplateView = ({ name }: { name: string }) => {
    const {
        loading,
        error,
        data: { messageTemplate: { value } } = { messageTemplate: {} },
    } = useQuery<{
        messageTemplate: {
            value: string;
        };
    }>(messageTemplateQuery, {
        variables: {
            name,
        },
    });

    if (error) {
        return <ErrorBox error={error} />;
    }

    if (loading) {
        return <SpinnerIcon />;
    }

    return <MessageTemplateEditor name={name} value={value || ''} />;
};

export default MessageTemplateView;
