import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { memo, useState } from 'react';
import useBookings from '../../booking/hooks/useBookings';
import ErrorBox from '../../core/components/ErrorBox';
import formatMoney from '../../core/formatMoney';
import useSearchParams from '../../core/hooks/useSearchParams';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';

const previousMonth = new Date();
previousMonth.setDate(1);
previousMonth.setHours(0);
previousMonth.setMinutes(0);
previousMonth.setSeconds(0);
previousMonth.setTime(previousMonth.getTime() - 3600 * 1_000);

const defaultYear = previousMonth.getFullYear().toString();
const defaultMonth = (previousMonth.getMonth() + 1).toString();

const TouristTaxBreakdown = memo(() => {
    const { params, updateParam } = useSearchParams();
    const yearParam = params.year;
    const monthParam = params.month;

    const [year, setYear] = useState(yearParam || defaultYear);
    const [month, setMonth] = useState(monthParam || defaultMonth);

    const { loading, data, error } = useBookings({
        invoiceMonth: `${year}-${month.padStart(2, '0')}`,
        excludeStatuses: ['canceled'],
    });

    if (error) {
        return <ErrorBox error={error} />;
    }

    if (loading) {
        return <SpinnerIcon />;
    }

    const { bookings } = data;

    const bookingWithoutInvoice = bookings.filter(booking => !booking.invoice);

    const total = bookings.reduce(
        (a, b) =>
            a + (b.invoice?.grossPrice ?? 0) * (b.invoice?.currencyRate ?? 0),
        0
    );

    return (
        <div>
            <Paper sx={{ mb: 4, p: 2 }}>
                <Box display="flex" flexDirection="row" gap={2}>
                    <TextField
                        value={year}
                        onChange={({ currentTarget: { value } }) => {
                            setYear(value);
                            updateParam('year', value);
                        }}
                    />
                    <FormControl sx={{ width: 80 }}>
                        <InputLabel id="select-month">Month</InputLabel>
                        <Select
                            labelId="select-month"
                            value={month}
                            onChange={({ target: { value } }) => {
                                setMonth(value);
                                updateParam('month', value);
                            }}
                            label="Month"
                        >
                            {new Array(12).fill(0).map((_value, index) => (
                                <MenuItem key={index} value={index + 1}>
                                    {index + 1}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Box flexGrow={1} />
                    <Button
                        target="_blank"
                        variant="outlined"
                        href="https://drive.istvan.xyz/d/f/sQgUmNiM4Ls2D9n9R5enZoGzJtgvZQlK"
                    >
                        Get data XML
                    </Button>
                    <Button
                        target="_blank"
                        variant="contained"
                        href="https://ohp-20.asp.lgov.hu/nyitolap"
                    >
                        Submit statement
                    </Button>
                </Box>
            </Paper>
            {!!bookingWithoutInvoice.length && (
                <div style={{ color: 'red' }}>
                    {bookingWithoutInvoice.map(booking => (
                        <Alert key={booking.id} severity="error">
                            {booking.guestName} requires invoice!
                        </Alert>
                    ))}
                </div>
            )}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice</TableCell>
                            <TableCell>Guest</TableCell>
                            <TableCell>Checkin</TableCell>
                            <TableCell>Checkout</TableCell>
                            <TableCell>Revenue</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bookings
                            .slice()
                            .sort((a, b) => a.checkin.localeCompare(b.checkin))
                            .map(booking => (
                                <TableRow key={booking.id}>
                                    <TableCell>{booking.invoiceId}</TableCell>
                                    <TableCell>{booking.guestName}</TableCell>
                                    <TableCell>{booking.checkin}</TableCell>
                                    <TableCell>{booking.checkout}</TableCell>
                                    <TableCell style={{ textAlign: 'right' }}>
                                        {formatMoney(
                                            booking.revenue,
                                            booking.currency
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={3} />
                            <TableCell>Total:</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                {formatMoney(total, 'HUF')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}></TableCell>
                            <TableCell>Net:</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                {formatMoney(total / 1.04, 'HUF')}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={3}></TableCell>
                            <TableCell>Tax:</TableCell>
                            <TableCell sx={{ textAlign: 'right' }}>
                                {formatMoney((total / 1.04) * 0.04, 'HUF')}
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </div>
    );
});

export default TouristTaxBreakdown;
