/* eslint max-lines: ["error", 270] */
/* eslint @typescript-eslint/ban-types: 0 */
import { useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import SpinnerIcon from '../../main/component/icon/SpinnerIcon';
import {
    cleaningListQuery,
    createCleaningQuery,
    updateCleaningQuery,
    useRemoveCleaningMutation,
} from '../queries/cleaningQueries';

const CleaningForm = ({
    cleaning,
    onClose,
    suggestedTime,
}: {
    onClose: () => void;
    suggestedTime?: string;
    cleaning?: {
        id: string;
        time: string;
        note: string;
        value: number;
        paid: boolean;
    };
}) => {
    const [createCleaning] = useMutation(createCleaningQuery, {
        refetchQueries: [
            {
                query: cleaningListQuery,
            },
        ],
    });
    const [updateCleaning] = useMutation(updateCleaningQuery, {
        refetchQueries: [
            {
                query: cleaningListQuery,
            },
        ],
    });
    const [removeCleaning] = useRemoveCleaningMutation();
    const [time, setTime] = useState(cleaning?.time || suggestedTime || '');
    const [note, setNote] = useState(cleaning?.note ?? '');
    const [value, setValue] = useState(cleaning?.value ?? '110');
    const [paid, setPaid] = useState(cleaning?.paid ?? false);
    const [loading, setLoading] = useState(false);

    if (loading) {
        return <SpinnerIcon />;
    }

    return (
        <Dialog open>
            <DialogContent>
                <Box>
                    <label>
                        <div className="FormLabel">Time</div>
                        <input
                            type="text"
                            value={time}
                            onChange={event => {
                                setTime(event.target.value);
                            }}
                        />
                    </label>
                </Box>
                <Box>
                    <label>
                        <div className="FormLabel">Note</div>
                        <input
                            type="text"
                            value={note}
                            onChange={event => {
                                setNote(event.target.value);
                            }}
                        />
                    </label>
                </Box>
                <Box>
                    <label>
                        <div className="FormLabel">Value</div>
                        <input
                            type="text"
                            value={value}
                            onChange={event => {
                                setValue(event.target.value);
                            }}
                        />
                    </label>
                </Box>
                <Box>
                    <label>
                        <div className="FormLabel">Paid</div>
                        <input
                            type="checkbox"
                            checked={paid}
                            value="1"
                            onChange={() => {
                                setPaid(!paid);
                            }}
                        />
                    </label>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Cancel
                </Button>
                {cleaning && (
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setLoading(true);
                            removeCleaning({
                                variables: { id: cleaning.id },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                            onClose();
                        }}
                    >
                        Delete
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        setLoading(true);
                        if (cleaning) {
                            updateCleaning({
                                variables: {
                                    id: cleaning.id,
                                    time,
                                    note,
                                    value: +value,
                                    paid,
                                },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        } else {
                            createCleaning({
                                variables: {
                                    time,
                                    note,
                                    value: +value,
                                    paid,
                                },
                            })
                                .catch(error => {
                                    throw error;
                                })
                                .finally(() => {
                                    setLoading(false);
                                });
                        }
                        onClose();
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CleaningForm;
